div.nav {
    position: fixed;
    top: 0;
    padding: 20px;
    width: 100%;
    height: 80px;
    z-index: 1;  
    transition-timing-function: ease-in;
    transition: all 0.5s;
}
.navBlack{
    background: #111;
}

.navContents{
    display: flex;
    justify-content: space-between;
}
.navLogo {
    position: fixed;
    left: 0;
    top: 30px;
    padding-left: 40px;
    object-fit: contain;
    width: 140px;
    height: auto;
    cursor: pointer;
}
.navAvatar {
    position: fixed;
    right: 20px;
    width: 40px;
    height: 40px;
    cursor: pointer;
}