 h2{
    font-size: 1.25em;
    line-height: 3em;
 }
 .row{
    color: #fff;
    margin-left: 20px;

 }
 .rowPosters{
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
 }
 .rowPosters::-webkit-scrollbar{
    display: none;
 }
 .rowPoster{
    max-height: 100px;
    object-fit: contain;
    margin-right: 10px;
    width: 100%;
    transition: transform 450ms;
    /* opacity: .8; */
 }
 .rowPoster:hover{
    transform: scale(1.10);
    opacity: 1;
 }
 .rowPosterLarge{
    max-height: 250px;
 }

 