*,
::before,
::after{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body{
  font-family: 'Helvetica', sans-serif;
  color: #fff; 
  background-color: #111;
}
h1{
  font-size: 4em;
  font-weight: 600;
}
h2{
  font-size: 1em;
  font-weight: 400;
}
button{
  font-size: 1.2em;
  font-weight: 500;
}
